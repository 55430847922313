import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"

import Layout from '../components/Layout'
import SEO from "../components/seo"
import { Avatar, CardContent, Chip, Divider, Icon, List, ListItem, ListItemAvatar, ListItemText, Paper } from "@material-ui/core"
import BusinessIcon from "@material-ui/icons/Business"

const pageTitle = "Companies that sponsor your visa"

const TagsPage = ({
  data: {
    allJobsJson: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
<Layout>
  <SEO title={pageTitle} description="A list of companies that provides Visa sponsorship."/>
  <div>
    <Helmet title={pageTitle} />
    <Paper>
      <CardContent>
      <Chip
            icon={<BusinessIcon />}
            label="Companies that you can work for"
            color="primary"
            variant="outlined"
      />
      <List>
        {group.map(tag => (
          <ListItem key={tag.fieldValue} dense divider>
            <ListItemAvatar>
              {/* fetching these images blocks the rendering, we need to bring back gatsby-images  */}
              <Avatar alt={tag.fieldValue} variant="rounded" src={`/logo/${tag.nodes[0].companyLogo}`} />
            </ListItemAvatar>
            <ListItemText>
              <Link key={tag.fieldValue} to={`/companies/${kebabCase(tag.fieldValue)}`}>
                <Chip key={`chip-${tag.fieldValue}`} label={`#${tag.fieldValue}`} size='small' clickable={true} />
              </Link>
            </ListItemText>
          </ListItem>
        ))}
      </List>
      </CardContent>
    </Paper>
  </div>
</Layout>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allJobsJson: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

//TODO: fix the nodes, it returns them all. We should have some distinct
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allJobsJson(limit: 2000) {
      group(field: author___a10_name) {
        fieldValue
        totalCount
        nodes {
          companyLogo
        }
      }
    }
  }
`